<template>
  <Layout :tituloPagina="`Pagos | Selección del cliente | Resumen ${ fecha_inicial == fecha_final ? 'del día' : 'de los días' }`">
    <div class="row">
      <div class="col-md-3 col-sm-12">
        <div class="row">
          <div class="col-sm-5">
            <label>Fecha inicial</label>
            <Datepicker
              ref="fecha_inicial"
              v-model="fecha_inicial"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
            />
          </div>
          <div class="col-sm-5 col-9">
            <label>Fecha final</label>
            <Datepicker
              ref="fecha_final"
              v-model="fecha_final"
              :class="'form-control bg-white'"
              :inputFormat="'dd/MM/yyyy'"
            />
          </div>
          <div class="col-sm-2 col-3 mt-4">
            <button class="btn btn-success mt-1" @click="refrescarAbonos()" title="Filtrar">
              <i class="mdi mdi-filter"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-3 mt-4" v-show="usuarioActEsAdmin">
        <div class="input-group mt-1">
          <span class="input-group-text">
            <i class="mdi mdi-account"></i>
            Usuario
          </span>
          <select v-model="id_usuario" class="form-select" @change="refrescarAbonos()">
            <option :value="null">Todos</option>
            <option :value="-1">Mi servicio de internet</option>
            <option
              :value="usuario.id"
              v-for="usuario in usuarios"
              :key="usuario.id"
            >
              {{ usuario.nombre }} {{ usuario.apellido_paterno }} {{ usuario.apellido_materno }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3 mt-4">
        <div class="input-group mt-1">
          <span class="input-group-text">
            <i class="mdi mdi-cash"></i>
            Metodo pago
          </span>
          <select v-model="id_metodo_pago" class="form-select" @change="refrescarAbonos()">
            <option :value="null">Todos</option>
            <option
              :value="metodo.id"
              v-for="metodo in metodos_pago"
              :key="metodo.id"
            >
              {{ metodo.nombre }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-3 mt-4" v-if="id_metodo_pago != 1 && id_metodo_pago != null">
        <div class="input-group mt-1">
          <span class="input-group-text">
            <i class="mdi mdi-bank"></i>
            Banco
          </span>
          <select v-model="id_banco" class="form-select" @change="refrescarAbonos()">
            <option :value="null">Todos</option>
            <option
              :value="banco.id"
              v-for="banco in bancos"
              :key="banco.id"
            >
              {{ banco.nombre }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-3 col-md-9 pt-2 mb-3">
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="nota"
            v-model="columnas.nota"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="nota">Nota</label>
        </div>
        &nbsp; 
        <div class="form-check font-size-16" style="display: inline-block;">
          <input
            class="form-check-input"
            type="checkbox"
            id="idOperacion"
            v-model="columnas.id_operacion"
            @change="actualizarValoresColumnasEnLocalStorage()"
          />
          <label class="form-check-label" for="idOperacion">ID de opecación</label>
        </div>
      </div>
      <div class="col-md-3 text-right" v-if="abonos.length > 0" style="padding:10px">
        <span class="badge bg-secondary bx-pull-right bg-gradient font-size-12">
          {{ nAbonosTotales }} pagos registrados
        </span>
      </div>
    </div>


    <div class="card">
      <div class="card-body">
        <SimpleBar
          class="table-responsive"
          data-simplebar
          style="max-height: 600px"
          v-if="abonos.length > 0"
        >
          <table
            class="table table-bordered table-hover table-striped table-nowrap"
            style="min-width: 700px;" id="tabla-resumen"
          >
            <thead>
              <tr>
                <th>ID</th>
                <th>Cliente</th>
                <th style="width: 200px">Fecha y hora</th>
                <th style="width: 130px">Tipo</th>
                <th>Facturas pagadas</th>
                <th>Importe</th>
                <th v-show="columnas.nota">Nota</th>
                <th v-show="columnas.id_operacion">ID de operación</th>
                <th style="min-width: 200px">Usuario que cobró</th>
                <th style="width: 150px">Acción</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="abono in abonos">
                <tr v-if="true" :key="abono.id">
                  <td class="fw-semibold">
                    {{ abono.id }}
                  </td>
                  <td>
                    <h5 class="font-size-14" :class="columnas.nota || columnas.id_operacion ? 'text-truncate' : ''">
                      <router-link
                        :to="{
                          name: 'tableroCliente',
                          params: { id_cliente: abono.id_cliente }
                        }"
                        v-show="
                          abono.id_cliente != undefined &&
                          abono.id_cliente != null
                        "
                        :title="abono.nombre_cliente"
                      >
                        {{ abono.nombre_cliente }}
                      </router-link>
                    </h5>
                  </td>
                  <td>
                    <span class="fw-semibold">Fecha:</span>{{ formatoFechaYHora(abono.fecha, "fecha") }}<br>
                    <span class="fw-semibold">Hora:</span>{{ formatoFechaYHora(abono.fecha, "hora") }}
                  </td>
                  <td>
                    <span v-show="abono.id_metodo_pago == 1">
                      Efectivo
                    </span>
                    <span
                      v-show="abono.id_metodo_pago == 2"
                      class="text-info"
                    >
                      Depósito
                    </span>
                    <span
                      v-show="abono.id_metodo_pago == 3"
                      class="text-warning"
                    >
                      Transferencia
                    </span>
                    <span
                      v-show="abono.id_metodo_pago == 4"
                      class="text-success"
                    >
                      PayPal
                    </span>
                    <span
                      v-show="abono.id_metodo_pago == 102"
                      class="text-primary"
                    >
                      Mercado Pago
                    </span>

                    <button
                      @click="palancaMostrarBanco(abono)"
                      v-show="abono.id_metodo_pago == 2"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        class="mdi"
                        :class="{
                          'mdi-chevron-down': !abono.mostrar_banco, 
                          'mdi-chevron-up': abono.mostrar_banco
                        }"
                      ></i>
                    </button>
                  </td>
                  <td>
                    <router-link
                      :to="{ name: 'factura', params: { id: factura.id } }"
                      v-for="(factura, index) in abono.facturas"
                      :key="index"
                      class="me-1"
                    >
                      {{ factura.ns }}
                      <br v-if="((index+1) % 4) == 0">
                    </router-link>
                  </td>
                  <td class=" text-left">
                    {{ formatoMoneda(abono.importe) }}
                    <br v-show="abono.cancelado">
                    <span
                      v-show="abono.cancelado"
                      class="text-danger"
                    > 
                      Cancelado
                    </span>

                    <button
                      @click="palancaMostrarCancelacion(abono)"
                      v-show="abono.cancelado"
                      class="btn btn-light btn-sm"
                    >
                      <i
                        class="mdi"
                        :class="{
                          'mdi-chevron-down': !abono.mostrar_cancelacion, 
                          'mdi-chevron-up': abono.mostrar_cancelacion
                        }"
                      ></i>
                    </button>
                  </td>
                  <td v-show="columnas.nota">
                    <p class="text-truncate font-size-14" :title="abono.nota">
                      {{ abono.nota }}
                    </p>
                  </td>
                  <td v-show="columnas.id_operacion">
                    {{ abono.id_operacion }}
                  </td>
                  <td>
                    {{ abono.nombre_usuario }}
                  </td>
                  <td>
                    <div class="btn-group">
                      <button class="btn btn-soft-dark btn-sm" @click="imprimirTicketPago(abono)">
                        <i class="mdi mdi-printer"></i>
                        Imprimir
                      </button>
                      <button class="btn btn-danger btn-sm btn-outline"
                        @click="cancelarAbono(abono)"
                        v-if="!abono.cancelado && tienePermiso('cmpCancelarAbono', 'componente')"
                        title="Cancelar abono">
                        <i class="mdi mdi-cancel"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-if="abono.mostrar_banco" :key="`banco-abono-${abono.id}`">
                  <td colspan="3"></td>
                  <td colspan="7">
                    <strong>Banco:</strong> {{ abono.nombre_banco }}
                  </td>
                </tr>
                <tr v-if="abono.mostrar_cancelacion" :key="`cancelacion-abono-${abono.id}`">
                  <td colspan="4">
                    <strong>Cancelado por:</strong> {{ abono.nombre_usuario_cancelo }}
                  </td>
                  <td colspan="7">
                    <strong>Motivo de cancelación:</strong> {{ abono.motivo_cancelacion }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </SimpleBar>
        <div v-if="abonos.length > 0" class="table-responsive">
          <table class="table table-hover table-bordered table-nowrap" style="width: 400px">
            <tbody>
              <tr>
                <td class="text-right"><label>Total de efectivo</label></td>
                <td class="text-right">{{ formatoMoneda(totalEfectivo) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total de depósitos</label></td>
                <td class="text-right">{{ formatoMoneda(totalDepositos) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total de transferencia</label></td>
                <td class="text-right">{{ formatoMoneda(totalTransferencia) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total de paypal</label></td>
                <td class="text-right">{{ formatoMoneda(totalPaypal) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total de mercado pago</label></td>
                <td class="text-right">{{ formatoMoneda(totalMercado) }}</td>
              </tr>
              <tr>
                <td class="text-right"><label>Total</label></td>
                <td class="text-right">{{ formatoMoneda(total) }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="height: 300px;" v-show="abonos.length == 0">
          No hay abonos registrados
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button @click="atras()" class="btn btn-secondary">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button @click="imprimirPagos()" class="btn btn-info">
          <i class="mdi mdi-printer"></i>
          Imprimir
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
/**
 * Componente del resumen del día
 */
import Layout from '@/views/layouts/main'
var accounting = require('accounting/accounting.js')
import API from '@/API.js'
import AbonoSrv from '@/services/AbonoSrv.js'
import UsuarioSrv from '@/services/UsuarioSrv.js'
import MetodoPagoSrv from '@/services/MetodoPagoSrv.js'
import BancoSrv from '@/services/BancoSrv.js'
import Datepicker from 'vue3-datepicker'
import moment from 'moment'
import { todoGetters } from "@/state/helpers"
import { SimpleBar } from "simplebar-vue3"
import Swal from 'sweetalert2'
import md5 from 'md5'
export default {
  name: 'ResumenDia',
  components: { Layout, Datepicker, SimpleBar },
  data() {
    return {
      API: API,
      token: localStorage.getItem('argusblack.token'),
      abonos: [],
      baseUrl: window.location.origin, 
      fecha_inicial: '',
      fecha_final: '',
      totalEfectivo: 0,
      totalDepositos: 0,
      totalTransferencia: 0,
      totalPaypal: 0,
      totalMercado: 0,
      total: 0,
      nAbonosTotales: 0,
      id_usuario: null,
      id_banco: null,
      id_metodo_pago: null,
      usuarios: [],
      bancos: [],
      metodos_pago:[],
      columnas: {
        nota: true,
        id_operacion: false
      },
      intervals: {
        actualizacion: null
      }
    }
  },
  computed: {
    ...todoGetters,
    monedaSistema:function(){
      return this.$store.state.todo.moneda_sistema
    },
    usuario: function() {
      var self = this
      return self.$store.state.todo.usuario
    },
    usuarioActEsAdmin() {
      return this.usuarioActualEsAdministrador()
    },
  },
  created: function() {
    var self = this

    // Carga de la fecha del query
    if (this.$route.query.fecha_inicial) self.fecha_inicial = new Date(this.$route.query.fecha_inicial)
    if (this.$route.query.fecha_final) self.fecha_final = new Date(this.$route.query.fecha_final)
    if (this.$route.query.id_banco) self.id_banco = this.$route.query.id_banco
    if (this.$route.query.id_metodo_pago) self.id_metodo_pago = this.$route.query.id_metodo_pago
    if (this.$route.query.id_usuario) self.id_usuario = parseInt(this.$route.query.id_usuario)

    // Carga de los bancos, metodos de pago y usuarios
    self.cargarBancos()
    self.cargarMetodosPago()
    self.cargarUsuarios()

    // Si el usuario está cargado y es administrador
    if (self.usuarioActEsAdmin) self.id_usuario = self.id_usuario
    else self.id_usuario = self.usuario.id

    // Carga de la fecha del sistema
    if (self.fecha_inicial == '' || self.fecha_final == '') {
      // Carga de la fecha del sistema
      self.fecha_inicial = new Date()
      self.fecha_final = new Date()
      // Carga inicial de los abonos
      self.refrescarAbonos()
    } else {
      // Carga inicial de los abonos
      self.refrescarAbonos()
    }

    // Carga de las columnas visibles
    self.cargarValoresColumnasDeLocalStorage()
    // Intervalos para cada 10 min realizar la carga de los abonos
    self.intervals.actualizacion = setInterval(function() {
      self.refrescarAbonos()
    }, 600000)
  },
  beforeUnmount: function() {
    clearInterval(this.intervals.actualizacion)
  },
  watch: {
    uc (estaCargado) {
      let self = this

      // Si el usuario no está cargado se evita continuar
      if(!estaCargado) return 

      if (!self.usuarioActEsAdmin) {
        self.id_usuario = self.usuario.id
        self.refrescarAbonos()
      }
    },
    fecha_inicial: function(newVar,oldVar){
      var self = this

      if(oldVar == '') return

      self.refrescarAbonos()
    },
    fecha_final: function(newVar,oldVar){
      var self = this

      if(oldVar == '') return

      self.refrescarAbonos()
    }
  },
  methods: {
    actualizarValoresColumnasEnLocalStorage() {
      let self = this
      localStorage.setItem('argusblack.resumenDia.columnas', JSON.stringify(self.columnas))
    },
    atras: function() {
      this.$router.go(-1)
    },
    cargarValoresColumnasDeLocalStorage() {
      let self = this, columnas = localStorage.getItem('argusblack.resumenDia.columnas')

      if(columnas == null) return

      columnas = JSON.parse(columnas)
      Object.assign(self.columnas, columnas)
    },
    calcularTotales: function() {
      var self = this
      self.total = 0
      self.totalEfectivo = 0
      self.totalDepositos = 0
      self.totalTransferencia = 0
      self.totalPaypal= 0
      self.totalMercado= 0

      self.abonos.forEach(function(abono) {
        if(!abono.cancelado){
          self.total += abono.importe
          switch(abono.id_metodo_pago) {
            case 1: self.totalEfectivo += abono.importe; break
            case 2: self.totalDepositos += abono.importe; break
            case 3: self.totalTransferencia += abono.importe; break
            case 4: self.totalPaypal += abono.importe; break
            case 102: self.totalMercado += abono.importe; break
          }
        }
      })
    },
    cargarBancos:function() {
      var self = this

      BancoSrv.bancosJSON().then(response => {
        self.bancos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los bancos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarMetodosPago: function() {
      var self = this

      MetodoPagoSrv.metodosJSON().then(response => {
        self.metodos_pago = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los metodos de pago'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarUsuarios: function() {
      var self = this

      let params = { todos: false }

      UsuarioSrv.usuariosJSON(params).then(response => {
        self.usuarios = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los usuarios'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    formatoMoneda: function(numero) {
      return accounting.formatMoney(numero, { symbol: this.monedaSistema.simbolo, miles: ",", decimal: "." })+' '+this.monedaSistema.codigo
    },
    formatoFechaYHora(mt, tipo = 'fecha') {
      switch (tipo) {
        case 'fecha':
          return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY')
        break;
        case 'hora':
          return moment(mt, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') + ' hrs.'
        break;
      }
    },
    formatoFecha(mt){
      return moment(mt, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },
    cancelarAbono: function(abono) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "¿Cancelar abono?",
        html: `¿Está seguro de cancelar el abono <strong>${abono.id}</strong>?`,
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          self.motivoAbono(abono)
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    imprimirTicketPago(abono) {
      let url = API+"/abonos/"+abono.id+"/ticket/"+md5(abono.id.toString())
      window.open(url,'_blank')
    },

    // imprimirTicketPago: function(abono) {
    //   var self = this, 
    //       oReq = new XMLHttpRequest()
      
    //   oReq.open("GET", `${API}/abonos/${abono.id}/ticket`)
    //   oReq.setRequestHeader('Authorization', self.token)
    //   oReq.send();
    //   oReq.onreadystatechange = function(){
    //     if (oReq.readyState === 4 && oReq.status === 200) {
    //       var new_window = window.open(null,'_blank');
    //       new_window.document.write(oReq.responseText);
    //     }
    //   }
    // },

    imprimirPagos: function() {
      var self = this,
          url = API+"/abonos/imprimir-pagos?"+
                "fecha_inicial="+self.formatoFecha(self.fecha_inicial)+
                "&fecha_final="+self.formatoFecha(self.fecha_final)+
                (self.id_metodo_pago ? "&id_metodo_pago="+self.id_metodo_pago : '')+
                (self.id_banco ? "&id_banco="+self.id_banco : '')+
                (self.usuarioActEsAdmin ? (self.id_usuario ? "&id_usuario="+self.id_usuario : '') : "&id_usuario="+self.usuario.id )+
                "&con_facturas_abonadas=false"+
                "&_tk="+self.token
      
      window.open(url, '_blank')
    },

    motivoAbono: function(abono){
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false
      })

      swal.fire({
        title: "Motivo de cancelación",
        html: "Escriba el motivo de la cancelación:",
        input: "textarea",
        inputPlaceholder: "Escribe algo",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar!",
        showLoaderOnConfirm: true,
        confirmButtonColor: "#556ee6",
        cancelButtonColor: "#f46a6a"
      }).then(text => {
        if (text.value) {
          let params = { motivo_cancelacion: text.value }

          AbonoSrv.cancelar(abono.id,params).then(response => {
            swal.fire("Cancelado!", `Abono ${abono.id} cancelada por motivo: \n\n ${text.value}`, "success");
            self.refrescarAbonos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo cancelar el abono'
            }
            swal("Error", mensaje, "error");
            console.log(error)
          })
        } else if ( text.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },  
    palancaMostrarBanco: function(abono) {
      if (abono.mostrar_banco) abono.mostrar_banco = false
      else abono.mostrar_banco = true
    },
    palancaMostrarCancelacion: function(abono) {
      if (abono.mostrar_cancelacion) abono.mostrar_cancelacion = false
      else abono.mostrar_cancelacion = true
    },
    refrescarAbonos: function() {
      var self = this

      iu.spinner.mostrar('#tabla-resumen')

      let query = {
        fecha_inicial: self.fecha_inicial,
        fecha_final: self.fecha_final,
        id_metodo_pago: self.id_metodo_pago
      }

      if (self.uc) {
        if (self.usuarioActEsAdmin) Object.assign(query, { id_usuario: self.id_usuario })
      }

      let params = {
        fecha_inicial : self.formatoFecha(self.fecha_inicial),
        fecha_final : self.formatoFecha(self.fecha_final),
        id_metodo_pago: self.id_metodo_pago,
        id_usuario: self.id_usuario,
        pagina: 1,
        por_pagina: 10,
        todos: true,
        con_facturas_abonadas:false
      }

      if( self.id_metodo_pago != 1 && self.id_metodo_pago != null ){
        Object.assign(params, { id_banco: self.id_banco })
        Object.assign(query, { id_banco: self.id_banco })
      }

      this.$router.replace({ query: query }).catch(error => {})

      AbonoSrv.abonosJSON(params).then(response => {
        let res = response.data
        var abonos = res.abonos
        self.nAbonosTotales = res.total
        // Carga de bandera para mostrar el banco donde fue depositado
        abonos.forEach(function(abono, index) {
          abono.mostrar_banco = false
          abono.mostrar_cancelacion = false
        })

        self.abonos = abonos

        self.calcularTotales()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar los abonos del día'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(fin => {
        iu.spinner.ocultar('#tabla-resumen')
      })
    }
  },
  mounted() {
    iu.spinner.mostrar('#tabla-resumen')
  }
}
</script>
<style scoped>
.text-truncate {
  width: 180px;
}
</style>